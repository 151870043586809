import React from 'react';

const BookSVG = () => (
  <svg width="96px" height="96px" viewBox="0 0 96 96" version="1.1" role="img">
    <g stroke="none" strokeWidth="1" fill="none">
      <g transform="translate(-374.000000, -1209.000000)">
        <path
          d="M421.8692,1290.2584 L421.8692,1302.4068 C419.9352,1288.966 375.8992,1292.9072 375.8992,1292.9072 L375.8992,1215.4872 C375.8992,1215.4872 379.0292,1215.7036 384.0512,1216.3116"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <path
          d="M421.8692,1302.4068 C423.8032,1288.966 467.8392,1292.9072 467.8392,1292.9072 L467.8392,1215.4872 C467.8392,1215.4872 464.5704,1215.6964 459.6872,1216.2808"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <path
          d="M421.8692,1290.2584 C420.278,1279.5052 384.0512,1282.658 384.0512,1282.658 L384.0512,1211.952 C384.0512,1211.952 420.2784,1214.158 421.8692,1224.9112 L421.8692,1290.2584 L421.8692,1290.2584 Z"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <path
          d="M421.8692,1290.2584 C423.4604,1279.5052 459.6872,1282.658 459.6872,1282.658 L459.6872,1211.952 C459.6872,1211.952 423.46,1214.158 421.8692,1224.9112 C421.8692,1224.9268 421.8692,1290.2584 421.8692,1290.2584 L421.8692,1290.2584 Z"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <rect x="374" y="1209" width="96" height="96" />
      </g>
    </g>
  </svg>
);

const SkylineSVG = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
    <g>
      <g>
        <g>
          <path
            d="M480.36,494.742h-14.382V43.146c0-4.767-3.864-8.629-8.629-8.629H296.27c-4.766,0-8.629,3.862-8.629,8.629v37.393 h-17.258V43.146c0-4.767-3.864-8.629-8.629-8.629H224.36V8.629c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629v25.888 h-37.393c-4.766,0-8.629,3.862-8.629,8.629v37.393h-25.888c-4.766,0-8.629,3.862-8.629,8.629v71.91h-71.91 c-4.766,0-8.629,3.862-8.629,8.629v325.034H31.64c-4.766,0-8.629,3.862-8.629,8.629S26.875,512,31.64,512H480.36 c4.766,0,8.629-3.862,8.629-8.629S485.125,494.742,480.36,494.742z M178.337,51.775h74.786v28.764h-74.786V51.775z M149.573,494.742h-5.753V480.36c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629v14.382h-5.753v-28.764h28.764 V494.742z M207.101,273.258v14.845v206.639h-40.27v-37.393c0-4.767-3.864-8.629-8.629-8.629H112.18 c-4.766,0-8.629,3.862-8.629,8.629v37.393h-40.27V178.337h143.82V273.258z M215.73,161.079h-71.91V97.798h143.82v166.831H224.36 v-28.764h25.888c4.766,0,8.629-3.862,8.629-8.629s-3.864-8.629-8.629-8.629H224.36v-28.764h25.888 c4.766,0,8.629-3.862,8.629-8.629s-3.864-8.629-8.629-8.629H224.36v-2.876C224.36,164.941,220.496,161.079,215.73,161.079z M368.18,494.742H224.36V288.103v-6.215h143.82V494.742z M448.719,494.742h-63.281V273.258v-11.506 c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629v2.876h-17.258v-2.876c0-4.767-3.864-8.629-8.629-8.629 s-8.629,3.862-8.629,8.629v2.876h-28.764V89.169V51.775h143.82V494.742z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M261.753,477.485c4.766,0,8.629-3.864,8.629-8.629V307.778c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v161.079C253.124,473.622,256.987,477.485,261.753,477.485z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M296.27,477.485c4.766,0,8.629-3.864,8.629-8.629V307.778c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v161.079C287.64,473.622,291.504,477.485,296.27,477.485z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M330.786,477.485c4.766,0,8.629-3.864,8.629-8.629V307.778c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v161.079C322.157,473.622,326.021,477.485,330.786,477.485z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M342.292,109.303c4.766,0,8.629-3.862,8.629-8.629V89.169c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C333.663,105.441,337.526,109.303,342.292,109.303z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M376.809,109.303c4.766,0,8.629-3.862,8.629-8.629V89.169c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C368.18,105.441,372.043,109.303,376.809,109.303z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M411.326,109.303c4.766,0,8.629-3.862,8.629-8.629V89.169c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C402.697,105.441,406.56,109.303,411.326,109.303z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M342.292,166.831c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C333.663,162.969,337.526,166.831,342.292,166.831z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M376.809,166.831c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C368.18,162.969,372.043,166.831,376.809,166.831z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M411.326,166.831c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C402.697,162.969,406.56,166.831,411.326,166.831z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M342.292,224.36c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C333.663,220.497,337.526,224.36,342.292,224.36z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M376.809,224.36c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C368.18,220.497,372.043,224.36,376.809,224.36z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M411.326,224.36c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C402.697,220.497,406.56,224.36,411.326,224.36z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M100.674,247.371c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C92.045,243.508,95.909,247.371,100.674,247.371z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M135.191,247.371c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C126.562,243.508,130.425,247.371,135.191,247.371z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M169.708,247.371c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C161.079,243.508,164.942,247.371,169.708,247.371z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M100.674,304.899c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C92.045,301.036,95.909,304.899,100.674,304.899z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M135.191,304.899c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C126.562,301.036,130.425,304.899,135.191,304.899z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M169.708,304.899c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C161.079,301.036,164.942,304.899,169.708,304.899z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M100.674,362.427c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C92.045,358.565,95.909,362.427,100.674,362.427z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M135.191,362.427c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C126.562,358.565,130.425,362.427,135.191,362.427z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M169.708,362.427c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C161.079,358.565,164.942,362.427,169.708,362.427z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M100.674,419.955c4.766,0,8.629-3.862,8.629-8.629V399.82c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C92.045,416.093,95.909,419.955,100.674,419.955z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M135.191,419.955c4.766,0,8.629-3.862,8.629-8.629V399.82c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C126.562,416.093,130.425,419.955,135.191,419.955z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M169.708,419.955c4.766,0,8.629-3.862,8.629-8.629V399.82c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C161.079,416.093,164.942,419.955,169.708,419.955z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M411.326,281.888c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C402.697,278.025,406.56,281.888,411.326,281.888z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M411.326,339.416c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C402.697,335.553,406.56,339.416,411.326,339.416z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M411.326,396.944c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C402.697,393.081,406.56,396.944,411.326,396.944z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M411.326,454.472c4.766,0,8.629-3.862,8.629-8.629v-11.506c0-4.767-3.864-8.629-8.629-8.629s-8.629,3.862-8.629,8.629 v11.506C402.697,450.609,406.56,454.472,411.326,454.472z"
            fill="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M258.876,135.191c0-4.767-3.864-8.629-8.629-8.629h-69.034c-4.766,0-8.629,3.862-8.629,8.629 c0,4.767,3.864,8.629,8.629,8.629h69.034C255.013,143.82,258.876,139.958,258.876,135.191z"
            fill="#ffc861"
            strokeWidth="2"
          />
        </g>
      </g>
    </g>
  </svg>
);

const HandshakeSVG = () => (
  <svg
    width="97px"
    height="97px"
    viewBox="0 0 97 97"
    version="1.1"
    role="img"
    aria-hidden="true"
  >
    <g stroke="none" strokeWidth="1" fill="none">
      <g transform="translate(-375.000000, -1338.000000)">
        <path
          d="M441.1912,1399.0928 L432.6248,1391.898"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <path
          d="M433.3188,1403.454 L426.3352,1397.738"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <path
          d="M395.1892,1388.3516 C397.6744,1395.4952 417.688,1410.9604 417.688,1410.9604 C422.5144,1415.0448 426.8772,1408.8108 422.9092,1405.4524 L419.8668,1402.4996"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <path
          d="M419.8664,1402.4996 L428.1428,1409.6864 C432.904,1413.716 437.5484,1407.1416 433.6144,1403.8124 L435.702,1405.5788 C439.8636,1409.1008 445.3416,1402.6052 441.1912,1399.0928 L441.5136,1399.3656 C445.6764,1402.8884 451.1532,1396.392 447.0024,1392.8796 L426.5152,1376.1748 C426.5152,1376.1748 422.5628,1377.6896 421.0344,1377.2528 C421.0344,1377.2528 415.1356,1386.3 410.8228,1383.1556 C406.51,1380.0112 410.8224,1366.6232 422.9456,1364.7104 C422.9456,1364.7104 428.4016,1363.1548 441.764,1370.0416 L446.7176,1368.5416"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <rect
          stroke="#ffc861"
          strokeWidth="4.00017018"
          transform="translate(389.870064, 1379.440373) rotate(195.812395) translate(-389.870064, -1379.440373) "
          x="382.323743"
          y="1363.81431"
          width="15.0926421"
          height="31.2521295"
        />
        <rect
          stroke="#ffc861"
          strokeWidth="4.00017018"
          transform="translate(458.177900, 1380.243633) rotate(-15.812395) translate(-458.177900, -1380.243633) "
          x="450.631579"
          y="1364.61757"
          width="15.0926421"
          height="31.2521295"
        />
        <path
          d="M400.814,1368.4884 C400.814,1368.4884 401.5176,1367.2108 416.9196,1366.8764"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <path
          d="M448.324,1395.014 C448.324,1395.014 452.1416,1396.0032 454.1448,1393.714"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <rect x="375.9936" y="1338.988" width="96" height="96" />
      </g>
    </g>
  </svg>
);

const MonitorSVG = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 33 33">
    <g>
      <path
        d="M30.5,26.109h-28c-1.378,0-2.5-1.121-2.5-2.5V4.61c0-1.379,1.122-2.5,2.5-2.5h28c1.378,0,2.5,1.121,2.5,2.5v18.999 C33,24.988,31.878,26.109,30.5,26.109z M2.5,3.11C1.673,3.11,1,3.783,1,4.61v18.999c0,0.827,0.673,1.5,1.5,1.5h28 c0.827,0,1.5-0.673,1.5-1.5V4.61c0-0.827-0.673-1.5-1.5-1.5H2.5z"
        fill="#ffc861"
        strokeWidth="2"
      />
      <path
        d="M22.875,30.89h-12.75c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h12.75c0.276,0,0.5,0.224,0.5,0.5 S23.151,30.89,22.875,30.89z"
        fill="#ffc861"
        strokeWidth="2"
      />
      <path
        d="M19.384,11.662c-0.934,0-1.693-0.759-1.693-1.692s0.759-1.692,1.693-1.692c0.933,0,1.692,0.759,1.692,1.692 S20.317,11.662,19.384,11.662z M19.384,9.277c-0.382,0-0.693,0.311-0.693,0.692s0.311,0.692,0.693,0.692s0.692-0.311,0.692-0.692 S19.766,9.277,19.384,9.277z"
        fill="#ffc861"
        strokeWidth="2"
      />
      <path
        d="M19.384,18.978c-0.934,0-1.693-0.759-1.693-1.692s0.759-1.693,1.693-1.693c0.933,0,1.692,0.76,1.692,1.693 S20.317,18.978,19.384,18.978z M19.384,16.592c-0.382,0-0.693,0.312-0.693,0.693s0.311,0.692,0.693,0.692s0.692-0.311,0.692-0.692 S19.766,16.592,19.384,16.592z"
        fill="#ffc861"
        strokeWidth="2"
      />
      <path
        d="M12.674,15.32c-0.933,0-1.692-0.759-1.692-1.692s0.759-1.692,1.692-1.692s1.692,0.759,1.692,1.692 S13.607,15.32,12.674,15.32z M12.674,12.935c-0.382,0-0.692,0.311-0.692,0.692s0.311,0.692,0.692,0.692s0.692-0.311,0.692-0.692 S13.056,12.935,12.674,12.935z"
        fill="#ffc861"
        strokeWidth="2"
      />
      <path
        d="M13.741,13.594c-0.174,0-0.344-0.091-0.436-0.254c-0.136-0.24-0.052-0.545,0.188-0.682l4.578-2.593 c0.242-0.137,0.546-0.052,0.682,0.189c0.136,0.24,0.052,0.545-0.188,0.682l-4.578,2.593C13.909,13.574,13.824,13.594,13.741,13.594 z"
        fill="#ffc861"
        strokeWidth="2"
      />
      <path
        d="M18.318,17.252c-0.083,0-0.168-0.021-0.246-0.064l-4.579-2.593c-0.24-0.137-0.325-0.441-0.188-0.682 c0.136-0.241,0.44-0.325,0.682-0.189l4.579,2.593c0.24,0.137,0.325,0.441,0.188,0.682C18.662,17.162,18.492,17.252,18.318,17.252z"
        fill="#ffc861"
        strokeWidth="2"
      />
      <path
        d="M16.5,30.89c-0.276,0-0.5-0.224-0.5-0.5v-4.781c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v4.781 C17,30.667,16.776,30.89,16.5,30.89z"
        fill="#ffc861"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const FolderSVG = () => (
  <svg
    width="56px"
    height="59px"
    viewBox="0 0 56 59"
    version="1.1"
    role="img"
    aria-hidden="true"
  >
    <g stroke="none" strokeWidth="1" fill="none">
      <g transform="translate(-541.000000, -803.000000)">
        <g transform="translate(540.000000, 804.000000)">
          <path
            d="M12.8566853,7.21007066 C12.2761355,5.85853777 11.3766285,4 10.6746846,4 L3.81758016,4 C2.81548319,4 2,4.81019956 2,5.80692506 L2,40.4729035 C2,41.4686687 2.81524516,42.2781481 3.81758016,42.2781481 L16.0564928,42.2781481"
            stroke="#ffc861"
            strokeWidth="2"
          />
          <path
            d="M47.2179998,18.5191509 L47.2179998,9.83381168 C47.2179998,8.82220251 46.4027547,8 45.4013718,8 L41,8"
            stroke="#ffc861"
            strokeWidth="2"
          />
          <path d="M47.0762737,16 L2,16" stroke="#ffc861" strokeWidth="2" />
          <polygon
            stroke="#ffc861"
            strokeWidth="2"
            points="42.18665 15.2590321 7.2353642 15.2590321 6 7.8496071 39.5005065 1"
          />
          <ellipse
            stroke="#ffc861"
            strokeWidth="2"
            cx="38.6057704"
            cy="39.747485"
            rx="16.6057704"
            ry="16.747485"
          />
          <polyline
            stroke="#ffc861"
            strokeWidth="2"
            points="46.7827492 39.5992633 38 39.5992633 38 27"
          />
        </g>
      </g>
    </g>
  </svg>
);

const DocumentSVG = () => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    width="512px"
    height="512px"
  >
    <g>
      <path
        d="M42.5,22h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,22,42.5,22z"
        fill="#ffc861"
      />
      <path
        d="M17.5,16h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S16.948,16,17.5,16z"
        fill="#ffc861"
      />
      <path
        d="M42.5,30h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,30,42.5,30z"
        fill="#ffc861"
      />
      <path
        d="M42.5,38h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,38,42.5,38z"
        fill="#ffc861"
      />
      <path
        d="M42.5,46h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S43.052,46,42.5,46z"
        fill="#ffc861"
      />
      <path
        d="M38.914,0H6.5v60h47V14.586L38.914,0z M39.5,3.414L50.086,14H39.5V3.414z M8.5,58V2h29v14h14v42H8.5z"
        fill="#ffc861"
      />
    </g>
  </svg>
);

const AgileSVG = () => (
  <svg
    width="96px"
    height="97px"
    viewBox="0 0 96 97"
    version="1.1"
    role="img"
    aria-hidden="true"
  >
    <g stroke="none" strokeWidth="1" fill="none">
      <g transform="translate(-374.000000, -1035.000000)">
        <polyline
          stroke="#ffc861"
          strokeWidth="4"
          points="377.4796 1085.1252 386.8736 1075.9092 396.0208 1085.2332"
        />
        <path
          d="M408.8624,1117.3672 C392.4684,1110.4476 383.9208,1092.7212 386.8736,1075.9092"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <polyline
          stroke="#ffc861"
          strokeWidth="4"
          points="448.4424 1122.0664 435.7536 1118.576 439.2176 1105.982"
        />
        <path
          d="M460.5456,1078.7308 C462.802,1096.3816 451.7724,1112.68 435.754,1118.576"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <polyline
          stroke="#ffc861"
          strokeWidth="4"
          points="443.7812 1041.1932 447.2468 1053.8884 434.646 1057.3284"
        />
        <path
          d="M400.3296,1052.8716 C414.3648,1041.9324 434.0088,1043.1128 447.2468,1053.8884"
          stroke="#ffc861"
          strokeWidth="4"
        />
        <rect x="374" y="1035.004" width="96" height="96" />
      </g>
    </g>
  </svg>
);

const TwitterSVG = () => (
  <svg viewBox="0 0 512 512" className="inline-icon twitter-icon">
    <path d="M419.6 168.6c-11.7 5.2-24.2 8.7-37.4 10.2 13.4-8.1 23.8-20.8 28.6-36 -12.6 7.5-26.5 12.9-41.3 15.8 -11.9-12.6-28.8-20.6-47.5-20.6 -42 0-72.9 39.2-63.4 79.9 -54.1-2.7-102.1-28.6-134.2-68 -17 29.2-8.8 67.5 20.1 86.9 -10.7-0.3-20.7-3.3-29.5-8.1 -0.7 30.2 20.9 58.4 52.2 64.6 -9.2 2.5-19.2 3.1-29.4 1.1 8.3 25.9 32.3 44.7 60.8 45.2 -27.4 21.4-61.8 31-96.4 27 28.8 18.5 63 29.2 99.8 29.2 120.8 0 189.1-102.1 185-193.6C399.9 193.1 410.9 181.7 419.6 168.6z" />
  </svg>
);

const GitHubSVG = () => (
  <svg viewBox="0 0 512 512" className="inline-icon github-icon">
    <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z" />
  </svg>
);

const RssSVG = () => (
  <svg viewBox="0 0 512 512" className="inline-icon rss-icon">
    <path d="M201.8 347.2c0 20.3-16.5 36.8-36.8 36.8 -20.3 0-36.8-16.5-36.8-36.8s16.5-36.8 36.8-36.8C185.3 310.4 201.8 326.8 201.8 347.2zM128.2 204.7v54.5c68.5 0.7 124 56.3 124.7 124.7h54.5C306.7 285.3 226.9 205.4 128.2 204.7zM128.2 166.6c57.9 0.3 112.3 22.9 153.2 63.9 41 41 63.7 95.5 63.9 153.5h54.5c-0.3-149.9-121.7-271.4-271.6-271.9V166.6L128.2 166.6z" />
  </svg>
);

export {
  BookSVG,
  SkylineSVG,
  HandshakeSVG,
  MonitorSVG,
  FolderSVG,
  DocumentSVG,
  AgileSVG,
  TwitterSVG,
  GitHubSVG,
  RssSVG,
};
